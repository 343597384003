import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Table, Space } from "antd";
import { createOutline, trashOutline, eyeOutline } from "ionicons/icons";
import axios from "axios";
import { IonIcon } from "@ionic/react";
import useFetch from "../../../Components/hooks/useFetch";
import { USER_API_URL } from "../../../Api/Api";

const { Column } = Table;

const Accleaning = () => {
  const [selectedData, setSelectedData] = useState({
    Name: "",
    Price: 0, // Assuming Price starts as a number
  });

  const [visible, setVisible] = useState(false);
  const [editing, setEditing] = useState(false); // State to track editing mode
  const [editId, setEditId] = useState(""); // State to store the ID of the item being edited

  const { data: DeepCleaning = [] } = useFetch(`${USER_API_URL}/ac/one`);
  const { data: DeepBooking = [] } = useFetch(`${USER_API_URL}/ac`);

  // Transform DeepCleaning data to a dictionary based on the Type
  const groupedData = DeepCleaning.reduce((acc, acType) => {
    acc[acType.Type] = acc[acType.Type] || [];
    acType.item.forEach((item) => {
      acc[acType.Type].push({
        _id: item._id,
        Name: item.name,
        Price: item.Price,
        Type: acType.Type,
      });
    });
    return acc;
  }, {});

  useEffect(() => {
    if (editing && editId) {
      const editItem = Object.values(groupedData)
        .flat()
        .find((item) => item._id === editId);
      if (editItem) {
        setSelectedData({
          Name: editItem.Name,
          Price: editItem.Price,
        });
      }
    }
  }, [editing, editId, groupedData]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setEditing(false);
    setSelectedData({
      Name: "",
      Price: 0,
    });
  };

  const onFinish = async () => {
    try {
      let response;
      const dataToSend = {
        Name: selectedData.Name,
        Price: selectedData.Price,
      };

      if (editing) {
        response = await axios.put(`${USER_API_URL}/ac/${editId}`, dataToSend, {
          withCredentials: true,
        });
      } else {
        response = await axios.post(`${USER_API_URL}/ac/one`, dataToSend, {
          withCredentials: true,
        });
      }
      Modal.success({
        content: editing ? "Successfully updated!" : "Successfully added!",
      });
      setVisible(false);
      setEditing(false);
      setSelectedData({
        Name: "",
        Price: 0,
      });
      // Optionally reload data
    } catch (error) {
      Modal.error({ content: "Failed to add/update item." });
      console.error("Error:", error);
    }
  };

  const handleChange = (name, value) => {
    setSelectedData({
      ...selectedData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFinish();
  };

  const handleEdit = (record) => {
    setEditing(true);
    setEditId(record._id);
    // showModal();
  };

  return (
    <div>
      <Modal
        title={editing ? "Edit Category" : "Open Orders"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Table dataSource={DeepBooking} pagination={false}>
          <Column title="Date" dataIndex="DateTime" key="DateTime" />
          <Column title="Name" dataIndex="Name" key="Name" />
          <Column title="Contact" dataIndex="Contact" key="Contact" />
          <Column
            title="Total Cost"
            dataIndex="Total_Amount"
            key="Total_Amount"
          />
          {/* <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <Button type="primary">Approve</Button>
                <Button type="danger">Decline</Button>
              </Space>
            )}
          /> */}
        </Table>
      </Modal>

      <div className="row">
        <div className="col"></div>
        <div className="col" align="right">
          <Button type="primary" onClick={showModal}>
            Open Orders
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          {Object.keys(groupedData).map((type) => (
            <div key={type}>
              <h4>{type}</h4>
              <Table
                dataSource={groupedData[type]}
                pagination={false}
                rowKey={(record) => record._id}
              >
                <Column title="Name" dataIndex="Name" key="Name" />
                <Column title="Price" dataIndex="Price" key="Price" />
                <Column
                  title="Actions"
                  key="actions"
                  render={(text, record) => (
                    <Space size="middle">
                      <Button type="link" onClick={() => handleEdit(record)}>
                        <IonIcon
                          icon={createOutline}
                          style={{ color: "blue" }}
                        />
                      </Button>
                      <Button type="link">
                        <IonIcon icon={trashOutline} style={{ color: "red" }} />
                      </Button>
                      <Button type="link">
                        <IonIcon icon={eyeOutline} style={{ color: "green" }} />
                      </Button>
                    </Space>
                  )}
                />
              </Table>
              <br />
              <br />
            </div>
          ))}
        </div>
        <div
          className="col"
          style={{ paddingLeft: 50, borderLeft: "1px solid lightgrey" }}
        >
          <div className="addSide">
            <h3>{editing ? "Edit Category" : "Manage Category"}</h3>
            <Form layout="vertical">
              <Form.Item label="Name">
                <Input
                  placeholder="Enter Name"
                  value={selectedData.Name}
                  onChange={(e) => handleChange("Name", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Price">
                <Input
                  placeholder="Enter Price"
                  type="number"
                  value={selectedData.Price}
                  onChange={(e) => handleChange("Price", e.target.value)}
                />
              </Form.Item>
              <Button type="primary" onClick={onFinish}>
                {editing ? "Update" : "Add"}
              </Button>
              {editing && <Button onClick={handleCancel}>Cancel</Button>}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accleaning;
