import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Table, Space, message } from "antd";
import { createOutline, trashOutline, eyeOutline } from "ionicons/icons";
import axios from "axios";
// import "./Style.css";
import { IonIcon } from "@ionic/react";
import useFetch from "../../../Components/hooks/useFetch";
import { USER_API_URL } from "../../../Api/Api";

const { Column } = Table;

const Carpetcleaning = () => {
  const [selectedData, setSelectedData] = useState({
    Name: "",
    Price: 0, // Assuming Price starts as a number
  });

  const [visible, setVisible] = useState(false);
  const [editing, setEditing] = useState(false); // State to track editing mode
  const [editId, setEditId] = useState(""); // State to store the ID of the item being edited

  const { data: DeepCleaning = [] } = useFetch(
    `${USER_API_URL}/carpetbooking/one`
  );
  const { data: DeepBooking = [] } = useFetch(`${USER_API_URL}/carpetbooking`);

  useEffect(() => {
    if (editing && editId) {
      // Find the item in DeepCleaning array by editId and set selectedData
      const editItem = DeepCleaning.find((item) => item._id === editId);
      if (editItem) {
        setSelectedData({
          Name: editItem.Name,
          Price: editItem.Price, // Assuming Price is a number
        });
      }
    }
  }, [editing, editId, DeepCleaning]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setEditing(false); // Reset editing mode on cancel
    setSelectedData({
      Name: "",
      Price: 0,
    });
  };

  const onFinish = async () => {
    try {
      let response;
      const dataToSend = {
        Name: selectedData.Name,
        Price: selectedData.Price,
      };
      if (editing) {
        response = await axios.put(
          `${USER_API_URL}/carpetbooking/one/${editId}`,
          dataToSend,
          {
            withCredentials: true,
          }
        );
      } else {
        // Add new item
        response = await axios.post(
          `${USER_API_URL}/carpetbooking/one`,
          dataToSend,
          {
            withCredentials: true,
          }
        );
      }
      console.log("Response data:", response.data);
      Modal.success({
        content: editing ? "Successfully updated!" : "Successfully added!",
      });
      setVisible(false);
      setEditing(false);
      setSelectedData({
        Name: "",
        Price: 0,
      });
        window.location.reload(); // Refresh or update state as needed
    } catch (error) {
      Modal.error({ content: "Failed to add/update item." });
      console.error("Error:", error);
    }
  };

  const handleChange = (Name, value) => {
    setSelectedData({
      ...selectedData,
      [Name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFinish();
  };

  const handleEdit = (record) => {
    setEditing(true);
    setEditId(record._id);
    // showModal();
  };

  const villaItems = DeepCleaning;
  const apartmentItems = DeepCleaning;

  return (
    <div>
      <Modal
        title={editing ? "Edit Category" : "Open Orders"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Table dataSource={DeepBooking} pagination={false}>
          <Column title="Date" dataIndex="DateTime" key="DateTime" />
          <Column title="Name" dataIndex="Name" key="Name" />
          <Column
            title="Total Cost"
            dataIndex="totalAmount"
            key="totalAmount"
          />
          <Column title="Contact" dataIndex="Contact" key="Contact" />
        </Table>
      </Modal>

      <div className="row">
        <div className="col"></div>
        <div className="col" align="right">
          <Button type="primary" onClick={showModal}>
            Open Orders
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <form onSubmit={handleSubmit}>
            <h4>Carpet Cleaning</h4>
            <Table
              dataSource={villaItems}
              pagination={false}
              rowKey={(record) => record._id}
            >
              <Column title="Name" dataIndex="Name" key="Name" />
              <Column title="Price" dataIndex="Price" key="Price" />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <Space size="middle">
                    <Button type="link" onClick={() => handleEdit(record)}>
                      <IonIcon icon={createOutline} style={{ color: "blue" }} />
                    </Button>
                    <Button type="link">
                      <IonIcon icon={trashOutline} style={{ color: "red" }} />
                    </Button>
                    <Button type="link">
                      <IonIcon icon={eyeOutline} style={{ color: "green" }} />
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </form>
        </div>
        <div
          className="col"
          style={{ paddingLeft: 50, borderLeft: "1px solid lightgrey" }}
        >
          <div className="addSide">
            <h3>{editing ? "Edit Category" : "Manage Category"}</h3>
            <Form layout="vertical">
              <Form.Item label="Name">
                <Input
                  placeholder="Enter Name"
                  value={selectedData.Name}
                  onChange={(e) => handleChange("Name", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Price">
                <Input
                  placeholder="Enter Price"
                  type="number"
                  value={selectedData.Price}
                  onChange={(e) => handleChange("Price", e.target.value)}
                />
              </Form.Item>
              <Button type="primary" onClick={onFinish}>
                {editing ? "Update" : "Add"}
              </Button>
              {editing && <Button onClick={handleCancel}>Cancel</Button>}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carpetcleaning;
