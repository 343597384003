import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Products from "./Pages/Dashboard/Products/Products";
import Menu from "./Components/Menu/Menu";
import Login from "./Pages/Accounts/Login";
import Sidebar from "./Pages/Navigation/Navigator";
// import "antd/dist/antd.css";

function App() {
  return (
    <>
      <Router>
         <Sidebar/>
      </Router>
    </>
  );
}

export default App;
