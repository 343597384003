import React, { useState, useEffect } from "react";
import { Layout, Menu, Drawer, Button, Card, Form, Input, message } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  HomeOutlined,
  MedicineBoxOutlined,
  SolutionOutlined,
  CoffeeOutlined,
  DashboardOutlined,
  FileProtectOutlined,
  LogoutOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Link, Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import HomeServices from "../Dashboard/HomeServices/HomeServices";
import DeepCleaningForm from "../Dashboard/HomeServices/Manage";
import Accleaning from "../Dashboard/Ac/Manage";
import Seatercleaning from "../Dashboard/Seater/Seater";
import Carpetcleaning from "../Dashboard/Carpet/Carpet";
import WashAndIroncleaning from "../Dashboard/WashandIron/Iron";
import Ironing from "../Dashboard/Ironing/Iron";
import DryCleaning from "../Dashboard/Drycleaning/Drycleaning";
import Shoe from "../Dashboard/Shoe/Shoe";
// import LoginScreen from "./LoginScreen";
import "./Style.css"; // You can create your own CSS file for custom styling

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const LoginScreen = ({ onLogin }) => {
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    const { username, password } = values;
    if (username === "smartbox" && password === "smartbox") {
      onLogin();
    } else {
      message.error("Invalid username or password");
    }
  };

  return (
    <div
      className="login-screen"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        title="Login"
        style={{
          maxWidth: "400px",
          margin: "0 auto",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log In
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInCookie = Cookies.get("loggedIn");
    if (loggedInCookie === "true") {
      setLoggedIn(true);
    }
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const handleLogin = () => {
    setLoggedIn(true);
    Cookies.set("loggedIn", "true");
  };

  const handleLogout = () => {
    setLoggedIn(false);
    Cookies.remove("loggedIn");
  };

  const onClose = () => {
    setVisible(false);
  };

  if (!loggedIn) {
    return <LoginScreen onLogin={handleLogin} />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Drawer
        title="Menu"
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <br />
        <br />
        <br />
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%", borderRight: 0 }}
        >
          {/* <SubMenu key="sub2" icon={<HomeOutlined />} title="Home Service">
            <Menu.Item key="3" icon={<DashboardOutlined />}>
              <Link to="/manage-home-service">Manage</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<DashboardOutlined />}>
              <Link to="/home-service-orders">Orders</Link>
            </Menu.Item>
          </SubMenu> */}
          <SubMenu
            key="sub3"
            icon={<MedicineBoxOutlined />}
            title="Deep Cleaning"
          >
            <Menu.Item key="5" icon={<DashboardOutlined />}>
              <Link to="/manage-deep-cleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="6" icon={<FileProtectOutlined />}>
              <Link to="/deep-service-orders">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" icon={<SolutionOutlined />} title="AC Cleaning">
            <Menu.Item key="7" icon={<DashboardOutlined />}>
              <Link to="/manage-ac-cleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="8" icon={<FileProtectOutlined />}>
              <Link to="/ac-cleaning-orders">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub5" icon={<CoffeeOutlined />} title="Sofa Cleaning">
            <Menu.Item key="9" icon={<DashboardOutlined />}>
              <Link to="/manage-sofa-cleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="10" icon={<FileProtectOutlined />}>
              <Link to="/sofa-cleaning-orders">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub6"
            icon={<DashboardOutlined />}
            title="Carpet Cleaning"
          >
            <Menu.Item key="11" icon={<DashboardOutlined />}>
              <Link to="/manage-carpet-cleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="12" icon={<FileProtectOutlined />}>
              <Link to="/carpet-cleaning-orders">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub7"
            icon={<DashboardOutlined />}
            title="Wash and Iron"
          >
            <Menu.Item key="14" icon={<DashboardOutlined />}>
              <Link to="/manage-WashAndIron">Manage</Link>
            </Menu.Item>
            <Menu.Item key="15" icon={<FileProtectOutlined />}>
              <Link to="/WashAndIron">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub9" icon={<DashboardOutlined />} title="Ironing">
            <Menu.Item key="20" icon={<DashboardOutlined />}>
              <Link to="/manage-ironing">Manage</Link>
            </Menu.Item>
            <Menu.Item key="21" icon={<FileProtectOutlined />}>
              <Link to="/ironing">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub10"
            icon={<DashboardOutlined />}
            title="Dry Cleaning"
          >
            <Menu.Item key="22" icon={<DashboardOutlined />}>
              <Link to="/manage-drycleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="23" icon={<FileProtectOutlined />}>
              <Link to="/drycleaning">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub61"
            icon={<DashboardOutlined />}
            title="Shoe Cleaning"
          >
            <Menu.Item key="122" icon={<DashboardOutlined />}>
              <Link to="/shoecleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="123" icon={<FileProtectOutlined />}>
              <Link to="/shoecleaning">Orders</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
        className="site-layout-background"
        style={{ background: "white" }}
      >
        <div className="logo">
          <img
            src="https://smartboxcleaningservices.com/static/media/Icon.821cb1eebf3655f6244f.png"
            alt="Logo"
            style={{ width: "100%", padding: "10px" }}
          />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%", borderRight: 0 }}
        >
          {/* <SubMenu key="sub2" icon={<HomeOutlined />} title="Home Service">
            <Menu.Item key="3" icon={<DashboardOutlined />}>
              <Link to="/manage-home-service">Manage</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<DashboardOutlined />}>
              <Link to="/home-service-orders">Orders</Link>
            </Menu.Item>
          </SubMenu> */}
          <SubMenu
            key="sub3"
            icon={<MedicineBoxOutlined />}
            title="Deep Cleaning"
          >
            <Menu.Item key="5" icon={<DashboardOutlined />}>
              <Link to="/manage-deep-cleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="6" icon={<FileProtectOutlined />}>
              <Link to="/deep-service-orders">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" icon={<SolutionOutlined />} title="AC Cleaning">
            <Menu.Item key="7" icon={<DashboardOutlined />}>
              <Link to="/manage-ac-cleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="8" icon={<FileProtectOutlined />}>
              <Link to="/ac-cleaning-orders">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub5" icon={<CoffeeOutlined />} title="Sofa Cleaning">
            <Menu.Item key="9" icon={<DashboardOutlined />}>
              <Link to="/manage-sofa-cleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="10" icon={<FileProtectOutlined />}>
              <Link to="/sofa-cleaning-orders">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub6"
            icon={<DashboardOutlined />}
            title="Carpet Cleaning"
          >
            <Menu.Item key="11" icon={<DashboardOutlined />}>
              <Link to="/manage-carpet-cleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="12" icon={<FileProtectOutlined />}>
              <Link to="/carpet-cleaning-orders">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub7"
            icon={<DashboardOutlined />}
            title="Wash and Iron"
          >
            <Menu.Item key="14" icon={<DashboardOutlined />}>
              <Link to="/manage-WashAndIron">Manage</Link>
            </Menu.Item>
            <Menu.Item key="15" icon={<FileProtectOutlined />}>
              <Link to="/WashAndIron">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub9" icon={<DashboardOutlined />} title="Ironing">
            <Menu.Item key="20" icon={<DashboardOutlined />}>
              <Link to="/manage-ironing">Manage</Link>
            </Menu.Item>
            <Menu.Item key="21" icon={<FileProtectOutlined />}>
              <Link to="/ironing">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub10"
            icon={<DashboardOutlined />}
            title="Dry Cleaning"
          >
            <Menu.Item key="22" icon={<DashboardOutlined />}>
              <Link to="/manage-drycleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="23" icon={<FileProtectOutlined />}>
              <Link to="/drycleaning">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub61"
            icon={<DashboardOutlined />}
            title="Shoe Cleaning"
          >
            <Menu.Item key="122" icon={<DashboardOutlined />}>
              <Link to="/shoecleaning">Manage</Link>
            </Menu.Item>
            <Menu.Item key="123" icon={<FileProtectOutlined />}>
              <Link to="/shoecleaning">Orders</Link>
            </Menu.Item>
          </SubMenu>
          <br />
          <br />
          <Button
            type="primary"
            onClick={handleLogout}
            style={{ marginBottom: 16, marginLeft: 10 }}
          >
            <LogoutOutlined /> Logout
          </Button>
        </Menu>
      </Sider>
      <Layout style={{ padding: "0 24px 24px" }}>
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Button
            type="primary"
            onClick={showDrawer}
            style={{ marginBottom: 16 }}
            className="menu-button"
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <Routes>
            <Route path="/manage-deep-service" element={<HomeServices />} />
            <Route path="/deep-service-orders" element={<DeepCleaningForm />} />
            <Route path="/ac-cleaning-orders" element={<Accleaning />} />
            <Route path="/sofa-cleaning-orders" element={<Seatercleaning />} />
            <Route path="/shoe-cleaning-orders" element={<Seatercleaning />} />
            <Route
              path="/carpet-cleaning-orders"
              element={<Carpetcleaning />}
            />
            <Route path="/WashAndIron" element={<WashAndIroncleaning />} />
            <Route path="/ironing" element={<Ironing />} />
            <Route path="/shoecleaning" element={<Shoe />} />
            <Route path="/drycleaning" element={<DryCleaning />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Sidebar;
