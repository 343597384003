import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, Table, Space, message } from "antd";
import { createOutline, trashOutline, eyeOutline } from "ionicons/icons";
import axios from "axios";
import "./Style.css";
import { IonIcon } from "@ionic/react";
import useFetch from "../../../Components/hooks/useFetch";
import { USER_API_URL } from "../../../Api/Api";

const { Column } = Table;

const DeepCleaningForm = () => {
  const [selectedData, setSelectedData] = useState({
    _id: "",
    path: "",
    name: "",
    price: "",
  });

  const [visible, setVisible] = useState(false);
  const [editing, setEditing] = useState(false); // State to track editing mode
  const [editId, setEditId] = useState(""); // State to store the ID of the item being edited

  const { data: DeepCleaning = [] } = useFetch(`${USER_API_URL}/deepcleaning`);
  const { data: DeepBooking = [] } = useFetch(`${USER_API_URL}/deepBook`);

  useEffect(() => {
    if (editing && editId) {
      // Find the item in DeepCleaning array by editId and set selectedData
      const editItem = DeepCleaning.find((item) => item._id === editId);
      if (editItem) {
        setSelectedData({
          ...editItem,
        });
      }
    }
  }, [editing, editId, DeepCleaning]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setEditing(false); // Reset editing mode on cancel
    setSelectedData({
      _id: "",
      path: "",
      name: "",
      price: "",
    });
  };

  const onFinish = async () => {
    try {
      let response;
      if (editing) {
        // Update existing item
        response = await axios.put(
          `${USER_API_URL}/deepcleaning/${selectedData._id}`,
          selectedData,
          {
            withCredentials: true,
          }
        );
      } else {
        const data = {
          path: selectedData.path,
          name: selectedData.name,
          price: selectedData.price,
        };
        // Add new item
        response = await axios.post(`${USER_API_URL}/deepcleaning`, data, {
          withCredentials: true,
        });
      }
      console.log("Response data:", response.data);
      // Modal.success({
      //   content: editing ? "Successfully updated!" : "Successfully added!",
      // });
      message.success({
        content: editing ? "Successfully updated!" : "Successfully added!",
      });
      setVisible(false);
      setEditing(false);

      //   setSelectedData({
      //     _id: "",
      //     path: "",
      //     name: "",
      //     price: "",
      //   });
      window.location.reload(); // Refresh or update state as needed
    } catch (error) {
      Modal.error({ content: "Failed to add/update item." });
      console.error("Error:", error);
    }
  };

  const handleChange = (name, value) => {
    setSelectedData({
      ...selectedData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFinish();
  };

  const handleEdit = (record) => {
    setEditing(true);
    setEditId(record._id);
    // showModal();
  };

  const villaItems = DeepCleaning.filter((item) => item.path === "Villa");
  const apartmentItems = DeepCleaning.filter(
    (item) => item.path === "Apartment"
  );

  return (
    <div>
      <Modal
        title={editing ? "Edit Category" : "Open Orders"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Table dataSource={DeepBooking} pagination={false}>
          <Column title="Date" dataIndex="DateTime" key="DateTime" />
          <Column title="Username" dataIndex="Userid" key="Userid" />
          <Column title="Total Cost" dataIndex="TotalPrice" key="TotalPrice" />
          <Column title="Contact" dataIndex="Contact" key="Contact" />
          <Column
            title="Category"
            key="Category"
            render={(text, record) => (
              <>
                {record.Villa.map((item, index) => (
                  <div key={index}>{item.name}</div>
                ))}
                {record.Apartment.map((item, index) => (
                  <div key={index}>{item.name}</div>
                ))}
              </>
            )}
          />
          {/* <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <Button type="primary">Approve</Button>
                <Button type="danger">Decline</Button>
              </Space>
            )}
          /> */}
        </Table>
      </Modal>

      <div className="row">
        <div className="col"></div>
        <div className="col" align="right">
          <Button type="primary" onClick={showModal}>
            Open Orders
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <form onSubmit={handleSubmit}>
            <h4>Villa</h4>
            <Table
              dataSource={villaItems}
              pagination={false}
              rowKey={(record) => record._id}
            >
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Price" dataIndex="price" key="price" />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <Space size="middle">
                    <Button type="link" onClick={() => handleEdit(record)}>
                      <IonIcon icon={createOutline} style={{ color: "blue" }} />
                    </Button>
                    <Button type="link">
                      <IonIcon icon={trashOutline} style={{ color: "red" }} />
                    </Button>
                    <Button type="link">
                      <IonIcon icon={eyeOutline} style={{ color: "green" }} />
                    </Button>
                  </Space>
                )}
              />
            </Table>

            <h4>Apartment</h4>
            <Table
              dataSource={apartmentItems}
              pagination={false}
              rowKey={(record) => record._id}
            >
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Price" dataIndex="price" key="price" />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <Space size="middle">
                    <Button type="link" onClick={() => handleEdit(record)}>
                      <IonIcon icon={createOutline} style={{ color: "blue" }} />
                    </Button>
                    <Button type="link">
                      <IonIcon icon={trashOutline} style={{ color: "red" }} />
                    </Button>
                    <Button type="link">
                      <IonIcon icon={eyeOutline} style={{ color: "green" }} />
                    </Button>
                  </Space>
                )}
              />
            </Table>

            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </form>
        </div>
        <div
          className="col"
          style={{ paddingLeft: 50, borderLeft: "1px solid lightgrey" }}
        >
          <div className="addSide">
            <h3>{editing ? "Edit Category" : "Manage Category"}</h3>
            <Form layout="vertical">
              <Form.Item label="Category">
                <Input
                  placeholder="Villa / Apartment / Other"
                  value={selectedData.path}
                  onChange={(e) => handleChange("path", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Name">
                <Input
                  placeholder="Studio | 2 BHK FLAT | 3 BHK FLAT"
                  value={selectedData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Price">
                <Input
                  placeholder="100"
                  value={selectedData.price}
                  onChange={(e) => handleChange("price", e.target.value)}
                />
              </Form.Item>
              <Button type="primary" onClick={onFinish}>
                {editing ? "Update" : "ADD"}
              </Button>
              {editing && <Button onClick={handleCancel}>Cancel</Button>}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeepCleaningForm;
