import React, { useState } from 'react';
import { Table, Tag, Space, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const { Column } = Table;

const HomeServices = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const data = [
    {
      key: '1',
      name: 'okurut george',
      email: 'okurut17@gmail.com',
      service: 'Cleaning',
      status: 'Completed',
      date: '2024-07-11T18:44:00.000Z',
      hours: 2,
      cleaners: 1,
      material: 10,
      frequency: 'Weekly',
      days: ['Monday', 'Thursday', 'Saturday'],
      totalAmount: 70,
      vat: 3.5,
      contact: '0523627783',
    },
    {
      key: '2',
      name: 'okurut george',
      email: 'okurut17@gmail.com',
      service: 'Cleaning',
      status: 'Completed',
      date: '2024-07-11T18:44:00.000Z',
      hours: 2,
      cleaners: 1,
      material: 10,
      frequency: 'Weekly',
      days: ['Monday', 'Thursday', 'Saturday'],
      totalAmount: 70,
      vat: 3.5,
      contact: '0523627783',
    },
  ];

  let searchInput;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

 

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Table dataSource={data}>
      <Column
        title="Customer Name & Email"
        dataIndex="name"
        key="name"
        render={(text, record) => (
          <Space>
            <span>{record.name}</span>
            <span>{record.email}</span>
          </Space>
        )}
        {...getColumnSearchProps('name')}
      />
      <Column title="Service" dataIndex="service" key="service" {...getColumnSearchProps('service')} />
      {/* <Column title="Date" dataIndex="date" key="date" render={formatDate} {...getColumnSearchProps('date')} /> */}
      <Column title="Date" dataIndex="date" key="date" render={(date) => formatDate(date)} {...getColumnSearchProps('date')} />
      <Column title="Hours" dataIndex="hours" key="hours" />
      <Column title="Cleaners" dataIndex="cleaners" key="cleaners" />
      <Column title="Material" dataIndex="material" key="material" />
      <Column title="Frequency" dataIndex="frequency" key="frequency" />
      <Column title="Days" dataIndex="days" key="days" render={(days) => days.join(', ')} />
      <Column title="Total Amount" dataIndex="totalAmount" key="totalAmount" />
      <Column title="VAT" dataIndex="vat" key="vat" />
      <Column title="Contact" dataIndex="contact" key="contact" {...getColumnSearchProps('contact')} />
      <Column
        title="Action"
        key="action"
        render={(text, record) => (
          <Space size="middle">
            <a>View</a>
            <a>Edit</a>
          </Space>
        )}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        filters={[
          { text: 'Completed', value: 'Completed' },
          { text: 'In Progress', value: 'In Progress' },
          { text: 'Pending', value: 'Pending' },
        ]}
        onFilter={(value, record) => record.status.indexOf(value) === 0}
        render={(status) => (
          <Tag
            color={
              status === 'Completed'
                ? 'green'
                : status === 'In Progress'
                ? 'blue'
                : 'volcano'
            }
          >
            {status}
          </Tag>
        )}
      />
    </Table>
  );
};

export default HomeServices;
